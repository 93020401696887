const searchSettings = {
  goodsNameId: {
    queryProperty: 'goodsNameId',
    searchType: 'QUERY_GOODS_NAME',
    searchProperty: 'goodsName',
    key: 'goodsId',
  },
  goodsCodeId: {
    queryProperty: 'goodsCodeId',
    searchType: 'QUERY_GOODS_CODE',
    searchProperty: 'goodsCode',
    key: 'goodsId',
  },
  locationId: {
    queryProperty: 'locationId',
    searchType: 'QUERY_STORAGE_LOCATION_CODE',
    searchProperty: 'storageLocationCode',
    key: 'locationId',
  },
  shipperGoodsCodeId: {
    queryProperty: 'shipperGoodsCodeId',
    searchType: 'QUERY_SHIPPER_GOODS_CODE',
    searchProperty: 'shipperGoodsCode',
    key: 'goodsId',
  },
  internationalCodeGoodsId: {
    queryProperty: 'internationalCodeGoodsId',
    searchType: 'QUERY_GOODS_INTERNATIONAL_CODE',
    searchProperty: 'goodsInternationalCode',
    key: 'goodsId',
  },
};

export default searchSettings;
