import { FormType } from '@/constant/form';
import { DateTime, DateTimeFormatYMD } from '@/constant/tableConfig';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = (GOODS_QUALITY) => [{
  label: '序号',
  type: 'index',
  minWidth: 50,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 160,
}, {
  label: '仓库名称',
  prop: 'warehouseName',
  minWidth: 120,
}, {
  label: '商品编码',
  prop: 'goodsCode',
  minWidth: 140,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '库位',
  prop: 'locationCode',
  minWidth: 130,
}, {
  label: '容器号',
  prop: 'warehousePalletCode',
  minWidth: 130,
}, {
  label: '换算率',
  prop: 'conversionRate',
  minWidth: 80,
}, {
  label: '默认单位数量',
  prop: 'defaultTotalInventory',
  minWidth: 120,
}, {
  label: '整单位数量',
  prop: 'intUnitNumber',
  minWidth: 120,
},
{
  label: '零单位数量',
  prop: 'totalCount',
  minWidth: 120,
}, {
  label: '单板数量',
  prop: 'veneerNumber',
  minWidth: 100,
}, {
  label: '库存总量（换算）',
  prop: 'conversionTotalInventory',
  minWidth: 150,
}, {
  label: '入库批次',
  prop: 'incomingBatch',
  minWidth: 140,
}, {
  label: 'LPN',
  prop: 'licensePlateNumber',
  minWidth: 130,
},
{
  label: '库龄',
  prop: 'stockAge',
  minWidth: 80,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  minWidth: 120,
  formatter: ({ goodsQuality }) => filterLabel(goodsQuality, GOODS_QUALITY),
}, {
  label: '货品备注',
  prop: 'goodsRemark',
  minWidth: 150,
}, {
  label: '温层',
  prop: 'temperatureLayerName',
  minWidth: 150,
}, {
  label: '库区',
  prop: 'zoneName',
  minWidth: 150,
}, {
  label: '国际条码',
  prop: 'internationalCode',
  minWidth: 100,
}, {
  label: '规格',
  prop: 'goodsSpecifications',
  minWidth: 90,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  minWidth: 80,
}, {
  label: '可用库存体积（m³）',
  prop: 'availableInventoryVolume',
  minWidth: 160,
},
{
  label: '可用库存重量（KG）',
  prop: 'availableInventoryHeight',
  minWidth: 160,
}, {
  label: '生产日期',
  prop: 'productDate',
  ...DateTimeFormatYMD,
},
{
  label: '到期日期',
  prop: 'expireDate',
  ...DateTimeFormatYMD,
}, {
  label: '保质期（天）',
  prop: 'expirationDate',
  minWidth: 120,
},
{
  label: '剩余天数',
  prop: 'restDay',
  minWidth: 100,
},
{
  label: '辅助数量',
  prop: 'assistNumber',
  minWidth: 100,
},
{
  label: '辅助单位',
  prop: 'assistUnit',
  minWidth: 100,
},
{
  label: '最后操作时间',
  prop: 'lastOperatingTime',
  ...DateTime,
},
];
export const serchFields = [{
  label: '查询时间',
  prop: 'queryTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'datetime',
    style: { width: '100%' },
  },
}, {
  label: '货品编码',
  prop: 'goodsCodeId',
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCodeId',
}, {
  label: '货品名称',
  prop: 'goodsNameId',
}, {
  label: '货主',
  prop: 'shipperId',
}, {
  label: '库区',
  prop: 'zoneNameId',
  component: FormType.INPUT,
}, {
  label: '库位',
  prop: 'locationId',
}, {
  label: '容器号',
  prop: 'WarehousePalletCode',
  component: FormType.INPUT,
}, {
  label: '入库批次',
  prop: 'incomingBatch',
  component: FormType.INPUT,
}, {
  label: 'LPN',
  prop: 'licensePlateNumber',
  component: FormType.INPUT,
}, {
  label: '国际条码',
  prop: 'internationalCodeGoodsId',
}, {
  label: '生产日期',
  prop: 'productDate',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'date',
    style: { width: '100%' },
  },
},
];
