<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="resetQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #zoneNameId>
        <el-select
          v-model="searchQuery.zoneNameId"
          filterable
          clearable
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.id"
            :label="item.zoneName"
            :value="item.id"
          />
        </el-select>
      </template>
      <template
        v-for="it in Object.keys(searchSettings)"
        :key="it"
        #[it]
      >
        <nh-remote-select
          v-model="searchQuery[it]"
          :remote-method="(query)=>remoteMethod(query,searchSettings[it])"
          :loading="loading.remoteMethod"
        >
          <el-option
            v-for="item in dictionaryEntity[searchSettings[it].searchProperty]"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          />
        </nh-remote-select>
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :show-table-setting="true"
      :columns="TABLECOLUMN(GOODS_QUALITY)"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '在库管理/库存管理/历史库存查询/[导出明细历史库存]'
          }"
          @click="handleExport"
        >
          导出明细历史库存
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
</template>
<script>
import loadingMixin from '@thales/loading';
import { mapState } from 'vuex';
import { SearchType } from '@/constant/form';
import InLibrary from '@/constant/pageNames/inLibrary';
import moment from '@/utils/moment';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import {
  historyStockPage, historyStockExport, warehouseZonePage, remoteMethodList,
} from './api';
import searchSettings from './constant';

export default {
  name: InLibrary.TRANSACTION_FLOW,
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      SearchType,
      searchSettings,
      tableData: [],
      searchQuery: {
        queryTime: moment.startOf(new Date()),
        goodsCodeId: null,
        goodsNameId: null,
        shipperId: null,
        zoneNameId: null,
        locationId: null,
        incomingBatch: null,
        shipperGoodsCodeId: null,
        warehouseId: null,
        internationalCodeGoodsId: null,
      },
      loading: {
        getTableData: false,
        handleExport: false,
        remoteMethod: false,
      },
      selectIds: [],
      dictionaryEntity: {},
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
    ...mapState({
      user: ({ user }) => user.accountInfo || {},
    }),
  },
  created() {
    this.getWarehouseZonelist();
    this.getTableData();
  },
  methods: {
    async getWarehouseZonelist(word) {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { zoneName: word || '' });
      this.warehouseZonelist = resp.records;
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    resetQuery() {
      this.handleQuery();
    },
    async getTableData() {
      this.selectIds = [];
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await historyStockPage(pagination, { ...this.searchQuery });
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      await historyStockExport({ ...this.searchQuery }, this.user.tenantName);
    },
    async remoteMethod(query, searchSetting) {
      if (!query) return;
      const { searchType, searchProperty, key } = searchSetting;
      const data = {
        searchKey: query,
        searchType,
      };
      const resp = await remoteMethodList(data);
      this.dictionaryEntity[searchProperty] = resp.map((item) => ({
        label: item[searchProperty],
        key: item[key],
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

</style>
