import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:历史库存分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18145
 */
export function historyStockPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/history_stock/page', data, {
    params,
  });
}

/**
  * 根据货主关键字进行查找货主
  * url: https://yapi.ops.yunlizhi.cn/project/271/interface/api/9352
  */
export function remoteMethodList(data) {
  return wmsPlusHttp.post('/stock/assistant_query', data);
}

/**
 * @description:明细历史库存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18145
 */
export function historyStockExport(data, tenantName) {
  return wmsPlusHttp.download('/warehouse_management_system/history_stock/export', data, { fileName: `${tenantName}历史库存明细`, extension: 'xlsx' });
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
